<template>
  <div  class="container">
    <a-row class="view" type="flex">
      <a-col :span="8">
        <p>管理交易主体</p>
        <p>8个</p>
      </a-col>
      <a-col :span="8">
        <p>待签署合同</p>
        <p>32份</p>
      </a-col>
      <a-col :span="8">
        <p>其他待操作事项</p>
        <p>4个</p>
      </a-col>
    </a-row>

    <p class="font-title">任务列表</p>

    <a-table :data-source="data" :columns="taskColumns" :pagination="false" :loading="loading" :rowKey="(record,index)=>{return index}">
      <template slot="action">
        <a>前往支付</a>
      </template>
    </a-table>
  </div>
</template>

<script>
const taskColumns = [
  {
    title: '任务类型',
    dataIndex: 'type',
    align: 'left',
  },
  {
    title: '关联交易主体',
    dataIndex: 'name',
    align: 'left',
  },
  {
    title: '任务内容',
    dataIndex: 'content',
    align: 'left',
  },
  {
    title: '提醒时间',
    dataIndex: 'time',
    align: 'left',
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' }
  },
]
export default {
  data() {
    return {
      taskColumns,
      data: [
        {type: '等待支付', name: '胶州从桂再生资源回收站', content: '订单 5123 等待支付操作', time: '2016-09-21'},
        {type: '签署合同', name: '胶州从桂再生资源回收站', content: '订单 5123 等待支付操作', time: '2016-09-21'},
        {type: '注册成功', name: '胶州从桂再生资源回收站', content: '订单 5123 等待支付操作', time: '2016-09-21'}
      ],
      loading: false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  font-size: 14px;
  .font-title {
    font-weight: 650;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    padding-left: 10px;
    margin-bottom: 24px;
  }
}
.view {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 24px 0;
  margin-bottom: 42px;
  p {
    text-align: center;
    &:nth-child(1) {
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 8px;
    }
    &:nth-child(2) {
      color: rgba(0, 0, 0, 0.85);
      font-size: 24px;
      line-height: 32px;
    }
  }
  /deep/ .ant-col {
    border-right: 1px solid #e9e9e9;
    &:nth-child(3) {
      border-right: none;
    }
  }
}
</style>